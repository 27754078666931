var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary","dark":"","to":"/"}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" Home ")]),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary","dark":"","to":"/products/0"}},'v-btn',attrs,false),on),[_vm._v(" Products ")])]}}])},[_c('v-list',_vm._l((_vm.items1),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.to}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary","dark":"","to":"/applications/1"}},'v-btn',attrs,false),on),[_vm._v(" Applications ")])]}}])},[_c('v-list',_vm._l((_vm.items2),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.to}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary","dark":"","to":"/about/2"}},'v-btn',attrs,false),on),[_vm._v(" About US ")])]}}])},[_c('v-list',_vm._l((_vm.items3),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.to}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }