<template>
  <v-app>
    <v-app-bar app flat height="100">
      <v-col
        cols="11"
        sm="7"
        md="4"
      >
        <v-img max-width="420px" alt="Huasu logo" src="./assets/logo.png"></v-img>
      </v-col>
      
      <v-spacer></v-spacer>
      
      <MainMenu class="d-none d-md-block"></MainMenu>
      <PopMenu class="d-md-none"></PopMenu>
    </v-app-bar>
    
    <v-main>
      <v-container fluid class="pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer padless color="blue-grey" dark>
      <v-col
        cols="12"
        class="d-none d-sm-block text-center pa-2"
      >
        <p class="font-weight-light ma-0">&copy; 2020 SHANGHAI HUASU TRADING CO.,LTD All Rights Reserved.</p>
      </v-col>
      <v-col
        cols="12"
        class="d-sm-none text-center pa-0"
      >
        &copy; 2020 SHANGHAI HUASU TRADING CO.,LTD
      </v-col>
      <v-col
        cols="12"
        class="d-sm-none text-center pa-0"
      >
        <p class="font-weight-light ma-0">All Rights Reserved.</p>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import MainMenu from './components/MainMenu'
import PopMenu from './components/PopMenu'

export default {
  components: {
    MainMenu,
    PopMenu
  }
}
</script>
