import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/Home.vue')
  },
  {
    path: '/about/:id',
    name: 'About',
    component: () => import('../components/About.vue')
  },
  {
    path: '/products/:id',
    name: 'Products',
    component: () => import('../components/Products.vue')
  },
  {
    path: '/applications/:id',
    name: 'Applications',
    component: () => import('../components/Applications.vue')
  },
  {
    path: '/productdetail/:id',
    name: 'ProductDetail',
    component: () => import('../components/ProductDetail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    }
    return {
        x: 0,
        y: 0
    }
  }
})

export default router
