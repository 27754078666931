<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          outlined
          color="blue-grey lighten-3"
          dark
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="blue-grey lighten-3"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Menu</v-toolbar-title>
        </v-toolbar>
        <v-list>
          <v-list-item
            to="/"
            @click="dialog = false"
          >
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-group
            :value="false"
            prepend-icon="mdi-package-variant"
          >
            <template v-slot:activator>
              <v-list-item-title>Products</v-list-item-title>
            </template>

            <v-list-item
              v-for="(item, i) in items1"
              :key="i"
              :to="item.to"
              @click="dialog = false"
            >
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-group
            :value="false"
            prepend-icon="mdi-apps"
          >
            <template v-slot:activator>
              <v-list-item-title>Applications</v-list-item-title>
            </template>

            <v-list-item
              v-for="(item, i) in items2"
              :key="i"
              :to="item.to"
              @click="dialog = false"
            >
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-group
            :value="false"
            prepend-icon="mdi-information"
          >
            <template v-slot:activator>
              <v-list-item-title>About Us</v-list-item-title>
            </template>

            <v-list-item
              v-for="(item, i) in items3"
              :key="i"
              :to="item.to"
              @click="dialog = false"
            >
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,

        items1: [
          {
            title: 'Generic',
            to: '/products/1',
          },
          {
            title: 'Enhanced',
            to: '/products/2',
          },
          {
            title: 'Toughened',
            to: '/products/3',
          },
          {
            title: 'Flame Retardant',
            to: '/products/4',
          },
          {
            title: 'Enhanced and Toughened',
            to: '/products/5'
          },
        ],
        items2: [
          {
            title: 'Electronic Products',
            to: '/applications/1',
          },
          {
            title: 'Daily Necessities',
            to: '/applications/2',
          },
          {
            title: 'Building Materials',
            to: '/applications/3',
          },
          {
            title: 'Machinery Parts',
            to: '/applications/4',
          },
          {
            title: 'Industrial Products',
            to: '/applications/5',
          },
          {
            title: 'Automotive Products',
            to: '/applications/6',
          },
        ],
        items3: [
          {
            title: 'Our Group',
            to: '/about/1',
          },
          {
            title: 'Our Company',
            to: '/about/2',
          },
          {
            title: 'Corporate Culture',
            to: '/about/3',
          },
          {
            title: 'Plant and Equipment',
            to: '/about/4',
          },
          {
            title: 'Certificate',
            to: '/about/5',
          },
          {
            title: 'Contact Us',
            to: '/about/6',
          }
        ],
      }
    },
  }
</script>
