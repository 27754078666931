<template>
  <div>
    <v-btn
      text
      color="primary"
      dark
      v-bind="attrs"
      v-on="on"
      to="/"
    >
      Home
    </v-btn>
    <v-menu
      open-on-hover
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          to="/products/0"
        >
          Products
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in items1"
          :key="index"
          :to="item.to"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      open-on-hover
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          to="/applications/1"
        >
          Applications
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in items2"
          :key="index"
          :to="item.to"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      open-on-hover
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          to="/about/2"
        >
          About US
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in items3"
          :key="index"
          :to="item.to"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: () => ({
    items1: [
      {
        title: 'Generic',
        to: '/products/1',
      },
      {
        title: 'Enhanced',
        to: '/products/2',
      },
      {
        title: 'Toughened',
        to: '/products/3',
      },
      {
        title: 'Flame Retardant',
        to: '/products/4',
      },
      {
        title: 'Enhanced and Toughened',
        to: '/products/5'
      },
    ],
    items2: [
      {
        title: 'Electronic Products',
        to: '/applications/1',
      },
      {
        title: 'Daily Necessities',
        to: '/applications/2',
      },
      {
        title: 'Building Materials',
        to: '/applications/3',
      },
      {
        title: 'Machinery Parts',
        to: '/applications/4',
      },
      {
        title: 'Industrial Products',
        to: '/applications/5',
      },
      {
        title: 'Automotive Products',
        to: '/applications/6',
      },
    ],
    items3: [
      {
        title: 'Our Group',
        to: '/about/1',
      },
      {
        title: 'Our Company',
        to: '/about/2',
      },
      {
        title: 'Corporate Culture',
        to: '/about/3',
      },
      {
        title: 'Plant and Equipment',
        to: '/about/4',
      },
      {
        title: 'Certificate',
        to: '/about/5',
      },
      {
        title: 'Contact Us',
        to: '/about/6',
      }
    ],
  }),
}
</script>
